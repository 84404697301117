import React, { useState } from 'react';
import './App.css';

function App() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const phoneNumber = '+919562211589';

  const openWhatsApp = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, '_blank'); // Open in a new tab
  };

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className="app">
      {/* Header */}
      <header className="app-header">
        <div className="logo">
          <img src="logo.png" alt="99designs" />
        </div>

        {/* Hamburger Icon for Mobile */}
        <div className="hamburger" onClick={toggleDrawer}>
          ☰
        </div>

        <nav className={`header-options ${isDrawerOpen ? 'open' : ''}`}>
          <ul>
            <li>Home</li>
            <li>Products</li>
            <li>How It Works</li>
            <li>FAQ</li>
            <li>Contact Us</li>
          </ul>
        </nav>

        <div className="header-contact">
          <span onClick={openWhatsApp} style={{ cursor: 'pointer', color: 'black', fontFamily: 'monospace', fontSize: '12px' }}>mob</span> | 
          <span onClick={openWhatsApp} style={{ cursor: 'pointer', color: 'black', fontFamily: 'monospace', fontSize: '12px' }}>+91 9562211589</span>
        </div>
      </header>

      {/* Main Section */}
      <main className="hero-section">
        <div className="product-image">
          <img src="logo.png" alt="Intenz Resurfacing Serum" />
          <div className='tag-line'>Resource from the Forest</div>
        </div>
        <div className='coming-soon-message'>
          <h1>We are coming soon!</h1>
          <p>Currently, we are working on our brand new website and will be launching soon.</p>
        </div>
      </main>

      {/* Footer */}
      <footer className="app-footer">
        <div className="footer-info coming-soon-message">
          <span>The Complete natural handmade products resources from the forest</span>
        </div>
      </footer>
    </div>
  );
}

export default App;
